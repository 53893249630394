<template>
  <div class="cp-box">
    <CellGroup>
      <template v-for="item in data">
        <!-- <Field> -->
        <template v-if="item.type === 'multiple' || item.name === '销售二级顾虑'"
                  :label="item.name"
                  label-width="100%">
          <div class="labels"
               :key="item.code">
            <p>{{item.name}}</p>
            <button v-for="$i in item.trees"
                    @click="onMultipleClick(item, $i)"
                    :key="$i.code"
                    :class="model[item.code] && model[item.code].includes($i.code) ? 'on' : ''"
                    type="button">
              {{$i.name}}
            </button>
          </div>
        </template>
        <!-- </Field> -->
        <SelectCell v-else
                    v-model="model[item.code]"
                    :label="item.name"
                    :key="item.code"
                    :options="item.options" />
      </template>
      <Field v-model="model.remark"
             type="textarea"
             label="备注信息"
             maxlength="200"
             placeholder="请输入备注信息"></Field>

    </CellGroup>
    <FooterBtn @cancel="cancelProfile"
               @ok="onNavBarClickRight"></FooterBtn>
  </div>
</template>

<script>
import { CellGroup, Field } from 'vant'
import FooterBtn from '_c/footerBtn/index'
import SelectCell from '@/components/dynamicForm/selectCell'
import './index.less';

export default {
  name: 'CustomerProfile',
  components: { CellGroup, Field, SelectCell, FooterBtn },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    show: {
      type: Boolean,
      default: false
    },
    detailCprofile: {
      type: Array,
      default: () => []
    },
    remark: {
      typeof: String,
      default: ''
    }
  },
  data () {
    return {
      model: {}
    }
  },
  watch: {
    detailCprofile: {
      // 监听传入的cp做数据回显
      handler (newVal) {
        if (!newVal) return
        newVal.forEach(item => {
          if (item.trees.length > 0) {
            if (item.type === 'single' && item.name !== '销售二级顾虑') {
              this.model[item.code] = item.trees[0]['code']
            } else {
              const a = []
              item.trees.forEach(el => {
                a.push(el.code)
              })
              this.model[item.code] = [...a]
            }
          }
        })
        this.$forceUpdate()
      },
      deep: true
    },
    remark: {
      handler (val) {
        this.model.remark = val
      }
    }
  },
  methods: {
    onMultipleClick (item, treeItem) {
      if (!this.model[item.code]) {
        this.$set(this.model, item.code, [])
      }
      if (this.model[item.code].indexOf(treeItem.code) === -1) {
        this.model[item.code].push(treeItem.code)
      } else {
        const i = this.model[item.code].indexOf(treeItem.code)
        this.model[item.code].splice(i, 1)
      }
      this.$forceUpdate()
    },
    cancelProfile () {
      // this.model = {}
      this.$emit('on-cancel')
    },
    onNavBarClickLeft () {
      this.$emit('on-cancel')
    },
    onNavBarClickRight () {
      this.$emit('on-confirm', this.model)
      this.$emit('on-cancel')
    }
  }
}
</script>
