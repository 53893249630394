<template>
  <App
    :title="
      chooseProfile ? 'customer-profile' : successcreate ? 'Created successfully' : 'Create'
    "
    :left-arrow="!chooseProfile"
    @click-left="onClickLeft"
  >
    <div v-if="!successcreate">
      <div class="createTel">
        <Icon
          name="phone-circle-o"
          color="#ccc"
          size="40"
          class="cu-phone-circle-o"
        ></Icon>
        <div>{{ model.phone }}</div>
      </div>
      <DynamicForm
        ref="form"
        v-model="model"
        :rules="rules"
        :fieldData="fieldData"
      />
      <!-- <CellGroup>
        <Field
          :value="profileData && profileData.length && '已选择' || ''"
          label-width="120"
          label="Customer Profile"
          readonly
          clickable
          @click="chooseProfile = true"
          input-align="right"
          placeholder="Please select"
          right-icon="arrow"
        />
      </CellGroup> -->
      <!-- <Popup v-model="chooseProfile"
             position="right"
             :style="{ width: '100%', height: '100%' }">
        <CustomerProfile @on-cancel="cancelProfile"
                         @on-confirm="confirmProfile"
                         :data="customerprofileList"
                         :show="chooseProfile"></CustomerProfile>
      </Popup> -->
      <div :style="chooseProfile ? 'display:block' : 'display:none'">
        <CustomerProfile
          @on-cancel="cancelProfile"
          @on-confirm="confirmProfile"
          :data="customerprofileList"
          :show="chooseProfile"
        ></CustomerProfile>
      </div>
      <div v-auth="'NewAddList-New'">
        <FooterBtn
          cancelText="Back"
          @cancel="chinal"
          @ok="onSubmitDebounced"
          okText="Submit"
        ></FooterBtn>
      </div>
    </div>
    <div v-else>
      <CreateSuccess @goAccounDetail="goAccoundDetail"></CreateSuccess>
    </div>
  </App>
</template>

<script>
import { CellGroup, Icon, Toast } from "vant";
import { debounce } from "lodash-es";
import CustomerProfile from "_c/customer-profile/index";
import CreateSuccess from "./createSuccess/index";
import FooterBtn from "_c/footerBtn/index";
import Field from "@/components/dynamicForm/field";
import DynamicForm from "@/components/dynamicForm";
import { getClueSource } from "@/api/chargequery/index";
import { getAreaTree, customerprofile } from "@/api/common";
import { getEntertainment, leadssave, leadssearch } from "@/api/clue";
import dayjs from "dayjs";
import "./index.less";
import "@/style/repairIcon.less";
export default {
  name: "newCreateForm",
  components: {
    // Field,
    // CellGroup,
    DynamicForm,
    CustomerProfile,
    Icon,
    FooterBtn,
    CreateSuccess,
  },
  data() {
    return {
      successcreate: false,
      model: {
        phone: "",
        name: "",
        source: "",
        activity: "",
        gender: "",
        birthday: null,
        city: null,
        isToshop: false,
        isImportant: false,
        intentionCarSeries: "",
        score: "",
        followupMessage: "",
        followupTime: null,
      },
      rules: {
        name: [{ required: true, message: "请填写客户姓名" }],
        source: [{ required: true, message: "请选择客户来源" }],
        gender: [{ required: true, message: "请选择用户性别" }],
        activity: [{ required: true, message: "Please select a campaign." }],
        intentionCarSeries: [{ required: true, message: "请选择意向车系" }],
        score: [{ required: true, message: "Please select a rating." }],
        followupMessage: [{ required: true, message: "Please write down the follow-up details." }],
        followupTime: [{ required: true, message: "Please select the next follow-up date." }],
      },
      chooseProfile: false,
      areaList: [], // 省市数据
      customerprofileList: [],
      profileData: [],
      sourceList: [], // 客户来源
      eosAccountId: "",
    };
  },
  computed: {
    onSubmitDebounced() {
      return debounce(this.onSubmit, 600, { leading: true });
    },
    fieldData() {
      return [
        {
          title: "Customer Profile",
          data: [
            {
              label: "Name",
              key: "name",
              props: { placeholder: "Please enter", maxlength: 20 },
            },
            {
              label: "Customer Source",
              key: "source",
              type: "select",
              options: this.sourceList,
              props: { placeholder: "Please select" },
            },
            {
              label: "活动类型",
              key: "activity",
              type: "select",
              options: this.activityList,
              visible: this.model.source === "020102",
            },
            {
              label: "Gender",
              key: "gender",
              type: "select",
              options: this.genderList,
            },
            {
              label: "Birth Date",
              key: "birthday",
              type: "datepicker",
              props: { placeholder: "Please select" },
              attrs: {
                type: "date",
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
              },
            },
            {
              label: "City",
              key: "city",
              type: "select",
              options: this.areaList,
              props: { placeholder: "Please select" },
            },
            { label: "If Walk-in", key: "isToshop", type: "switch" },
            { label: "If VIP", key: "isImportant", type: "switch" },
          ],
        },
        {
          title: "Customer Intent",
          data: [
            {
              label: "Interested Model",
              key: "intentionCarSeries",
              type: "select",
              options: this.intentionCarSeriesList,
              props: { placeholder: "Please select" },
            },
            {
              label: "Status",
              key: "score",
              type: "select",
              options: this.leadsScoreList,
              props: { placeholder: "Please select" },
            },
          ],
        },
        {
          title: "Follow-up",
          data: [
            {
              label: "Follow-up Details",
              key: "followupMessage",
              direction: "column",
              props: {
                type: "textarea",
                placeholder: "Please enter",
                maxlength: 1000,
              },
            },
            {
              label: "Next Follow-up Date",
              key: "followupTime",
              type: "datepicker",
              props: { placeholder: "Please select", labelWidth: 200 },
              attrs: { type: "date", minDate: new Date() },
            },
          ],
        },
      ];
    },
  },
  methods: {
    onClickLeft() {
      if (this.createSuccess) {
        this.createSuccess = false;
        this.init();
      } else {
        this.$router.go(-1);
      }
    },
    chinal() {
      this.$router.go(-1);
    },
    getTel() {
      this.model.phone = this.$route.query.tel;
    },
    // 基础数据
    baseData() {
      return this.$storage.get("baseData");
    },
    // 客户来源
    accountSourceList() {
      getClueSource().then((res) => {
        if (res.success) {
          this.sourceList = res.data
            .map((item) => {
              return {
                label: item.threelevelName,
                value: item.threelevelCode,
              };
            })
            .filter((item) => {
              return "020102" != item.value;
            });
        }
      });
    },
    // 意向车系
    intentionCarSeriesList() {
      return this.baseData()["intention_car_series"].map((item) => {
        return {
          label: item.key,
          value: item.value,
        };
      });
    },
    // 评分
    leadsScoreList() {
      return this.baseData()["leads_score"].map((item) => {
        return {
          label: item.key + "(" + item.value + ")",
          value: item.value,
        };
      });
    },
    // 性别
    genderList() {
      return this.baseData()["gende"].map((item) => {
        return {
          label: item.key,
          value: item.value,
        };
      });
    },
    onSubmit() {
      this.$refs.form.validate().then((valid) => {
        if (!valid) return;
        const options = {
          name: this.model.name,
          phone: this.model.phone,
          source: this.model.source,
          gender: this.model.gender,
          birthday:
            this.model.birthday == null
              ? null
              : dayjs(this.model.birthday).format("YYYY/MM/DD"),
          city: this.model.city,
          isToshop: this.model.isToshop,
          isImportant: this.model.isImportant,
          intentionCarSeries: this.model.intentionCarSeries,
          score: this.model.score,
          followupMessage: this.model.followupMessage,
          nextFollowUpTime:
            this.model.followupTime && this.model.followupTime.getTime(),
          level2Code: "002",
        };
        if (options.source === "020102") {
          options.campaignSource = this.model.activity;
          this.activityList.forEach((e) => {
            if (e.value === this.model.activity)
              options.campaignSourceName = e.label;
          });
        }
        if (this.profileData) {
          const profile = this.customerprofileList.map((item) => {
            return {
              name: item.name,
              code: item.code,
              type: item.type,
              trees: item.trees.filter((t) =>
                this.profileData.includes(t.code)
              ),
            };
          });
          options.profile = profile;
        }
        this.$requestHandler(leadssave, options).then((res) => {
          if (res.success) {
            const params = {
              phone: this.model.phone,
              carseries:
                this.$storage.get("baseData")["intention_car_series"][0][
                  "value"
                ],
            };
            leadssearch(params).then((e) => {
              if (e.success) {
                this.eosAccountId = e.data[0]["eosAccountId"];
              }
              this.successcreate = true;
            });
          } else {
            Toast(res.msg);
          }
        });
      });
    },
    cancelProfile() {
      this.chooseProfile = false;
    },
    confirmProfile(data) {
      this.profileData = Object.keys(data)
        .map((el) => {
          return data[el];
        })
        .toString()
        .split(",");
    },
    // 获取省市数据
    getAreaList() {
      getAreaTree().then((res) => {
        if (res.success) {
          this.areaList = res.data.map((e) => {
            e.children.map((item) => {
              delete item.children;
              return item;
            });
            return e;
          });
        }
      });
    },
    // 获取 Customer Profile 主数据
    getCustomerprofile() {
      return customerprofile().then((res) => {
        if (res.success) {
          this.customerprofileList = JSON.parse(res.data.template).map(
            (item) => {
              return {
                ...item,
                options: item.trees.map((it) => {
                  return {
                    label: it.name,
                    value: it.code,
                  };
                }),
              };
            }
          );
        }
      });
    },
    // 获取活动数据
    getActivityList() {
      const params = {
        page: 1,
        size: 10,
      };
      getEntertainment(params).then((res) => {
        if (res.success) {
          this.activityList = (res.data.records||[]).map((item) => {
            return {
              label: item.name,
              value: item.code,
            };
          });
        }
      });
    },
    goAccoundDetail() {
      this.$storage.set("cu_path_newCreateForm", true);
      this.$router.push({
        path: "/customerInfo",
        query: {
          id: this.eosAccountId,
        },
      });
    },
    init() {
      this.model.phone = "";
      this.model.name = "";
      this.model.source = "";
      this.model.activity = "";
      this.model.gender = "";
      this.model.birthday = null;
      this.model.isToshop = false;
      this.model.isImportant = false;
      this.model.intentionCarSeries = "";
      this.model.score = "";
      this.followupMessage = "";
      this.followupTime = null;
      this.getTel();
      this.accountSourceList();
      this.getAreaList();
      this.getCustomerprofile();
      this.getActivityList();
    },
    async continueToBack() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
        loadingType: "spinner",
        overlay: true,
      });
      const params = {
        phone: this.$route.query.tel,
        carseries:
          this.$storage.get("baseData")["intention_car_series"][0]["value"],
      };
      const res = await leadssearch(params);
      Toast.clear();
      if (res.data && res.data.length > 0) this.onClickLeft();
    },
  },
  mounted() {
    this.continueToBack();
    this.init();
  },
};
</script>
